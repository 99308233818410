const DEFAULT_SHOPIFY_LINK =
  'https://buy.getmnly.com/cart/add?items[0][id]=43714368045244&items[0][quantity]=1&items[0][selling_plan]=5537136828&items[0][properties][Note]=Blood%20Sample%20Kit%20%24199%20TODAY%20%E2%80%A2%20You%20will%20be%20charged%20for%20your%20Precision%20packs%20and%20Health%20Plan%20in%2030%20days.&return_to=/checkout';

  // const DEFAULT_SHOPIFY_LINK =
  // 'https://mnly-store.myshopify.com/cart/clear?return_to=/cart/add?items[][id]=43714403631292%26items[][quantity]=1%26items[][selling_plan]=5292884156%26items[][id]=43714368045244%26items[][quantity]=1%26return_to=/checkout';

const SHOPIFY_CHECKOUT_URL =
  process.env.SHOPIFY_CHECKOUT_URL || DEFAULT_SHOPIFY_LINK;

export default SHOPIFY_CHECKOUT_URL;
