import React, { useState } from 'react';
import { Box, Flex, Grid, GridItem, Text } from '../../../ui';
import { defaultHorizontalGap, defaultVerticalGap } from '../../../constants';
import BuyNowBtn from '../../../components/Buttons/BuyNowBtn';
import DetailsModal from '../../../components/DetailsModal';

const Section = ({
  text,
  iconPath,
  bg,
}: {
  text: string;
  iconPath: string;
  bg: string;
}) => {
  return (
    <Flex
      flexDirection="row"
      alignItems="center"
      bg={bg}
      gap="2rem"
      height="100%"
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        width={{ base: '50%', sm: '25%', lg: '33%', xl: '25%', '2xl': '20%' }}
      >
        <img width="50%" alt="MNLY Precision Men's Health" src={iconPath} />
      </Flex>

      <Flex alignItems="center">
        <Text
          display="inline-flex"
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontSize={{ base: '18px', sm: '14px', md: '16px', lg: '18px' }}
        >
          {text}
        </Text>
        <img
          style={{
            width: '1.5rem',
          }}
          src="/static/icons/tile.png"
        />
      </Flex>
    </Flex>
  );
};

const Summary = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      gap={defaultHorizontalGap}
      width="100%"
    >
      <GridItem colSpan={{ base: 1, sm: 2, lg: 1 }}>
        <Flex flexDirection="column" height="100%">
          <Section
            text="Health Assessment"
            iconPath="/static/ads/wholeman/lifestyle-assessment-phone.png"
            bg="#685848"
          />
          <Section
            text="19 Biomarker Blood Analysis"
            iconPath="/static/ads/wholeman/mnly-box.png"
            bg="#843A23"
          />
          <Section
            text="Precision Packs"
            iconPath="/static/ads/wholeman/pill-pack.png"
            bg="#A0856A"
          />
          <Section
            text="Personalized Health Plan"
            iconPath="/static/ads/wholeman/nutrition-phone.png"
            bg="#434B30"
          />
        </Flex>
      </GridItem>
      <GridItem colSpan={{ base: 1, sm: 2, lg: 1 }}>
        <Flex
          flexDirection="column"
          alignItems={{ sm: 'center', md: 'flex-start' }}
          gap={defaultVerticalGap}
        >
          <Text
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize="2xl"
            fontStyle="normal"
            lineHeight="114%"
            width={{
              base: '100%',
              sm: '100%',
              lg: '80%',
              xl: '75%',
            }}
            textAlign="left"
          >
            What's included
          </Text>
          <Flex
            borderColor="#7D7C7A"
            borderWidth="1px"
            width={{
              base: '100%',
              sm: '100%',
              lg: '80%',
              xl: '75%',
            }}
            p=".7rem"
          >
            <Text
              color="#7D7C7A"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="114%"
            >
              Developed by our team of Practitioners, men's health scientists,
              neuroscientists and peak performers. MNLY harnesses the power of
              blood analysis, machine learning, and AI to evaluate data from
              four essential components: Biological, Environmental, Nutritional,
              and Clinical analysis. By leveraging this advanced technology, we
              develop precise, evidence-based solutions that are tailored
              uniquely to each individual.
            </Text>
          </Flex>
          <Box
            borderColor="#7D7C7A"
            borderWidth="1px"
            width={{
              base: '100%',
              sm: '100%',
              lg: '80%',
              xl: '75%',
            }}
            p=".7rem"
          >
            <Text
              color="#7D7C7A"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="114%"
            >
              The MNLY Membership solution comes with:
              <Box ml="2rem" mt="1rem">
                <ul>
                  <li>Comprehensive lifestyle health assessment</li>
                  <li>1x 19 biomarker blood analysis kit</li>
                  <li>30 day supply Precision Packs</li>
                  <li>Personalized health plan via our digital platform</li>
                </ul>
              </Box>
            </Text>
          </Box>
          <Text color="#E3DBD3" fontFamily="DIN-Medium" fontSize="32px">
            $199 Today
          </Text>


          <Text
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize="16px"
            width="80%"
            mb="-2"
          >
            $249/month for your precision packs and plan.<Text>Pause, change or cancel anytime.</Text>
          </Text>
          
          <Text
            as="a"
            color="#E3DBD3"
            fontFamily="DIN-Regular"
            fontSize="16px"
            fontStyle="normal"
            lineHeight="114%"
            cursor="pointer"
            onClick={openModal}
            mt="-2"
            textDecoration={'underline'}
          >
            See Details
          </Text>

          <BuyNowBtn />
        </Flex>
      </GridItem>
      <DetailsModal isOpen={isModalOpen} onClose={closeModal} />
    </Grid>
  );
};

export default Summary;
